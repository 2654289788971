import React, { useState } from "react";
import HandleWindowSize from "./HandleWindowSize";
import Navigation from "./Navigation";

const DisplayNavigation = ({ page_name }) => {
  const width = HandleWindowSize();
  const [display, setDisplay] = useState(false);
  return (
    <div>
      {width <= 1024 ? (
        <div className="fixed">
          <div >
            <div className="nav-small">
              <button
                className="btn-nav-small"
                onClick={() => setDisplay(!display)}
              >
                Menu
              </button>
              <h1 className="title-small-nav">{page_name}</h1>
            </div>
          </div>
          {display && <Navigation size={"small"} closenav={setDisplay} />}
        </div>
      ) : (
        <Navigation size={"large"} closenav={setDisplay} />
      )}
    </div>
  );
};

export default DisplayNavigation;
