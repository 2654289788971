import React from 'react';

const Header = ({nom_page}) => {
    return (
        <div className="contain-header">
            <div className="header">
            <h1 className='titleHeader'>{nom_page}</h1>
            </div>
                
        </div>
    );
};

export default Header;