import React from "react";
import config from "../config";

const InformationPlante = ({ plante, SetIsEditing }) => {
  return (
    <div className="info_plante">
      <div className="card">
        <div className="middle-card">
          <div className="photo">
            {plante.photo === undefined ? (
              <p>Loading...</p>
            ) : plante.photo === "null" ? (
              <div className="imgContainer">
                <img
                  src={`${config.API_URL}images/${plante.photo}.png`}
                  alt=""
                  className="image"
                />
                <img
                  src={`${config.API_URL}images/${plante.photo}.png`}
                  alt=""
                  className="bgphoto"
                />
              </div>
            ) : (
              <div className="imgContainer">
                <img
                  src={`${config.API_URL}images/${plante.photo}`}
                  alt=""
                  className="image"
                />
                <img
                  src={`${config.API_URL}images/${plante.photo}`}
                  alt=""
                  className="bgphoto"
                />
              </div>
            )}
          </div>

          <div className="information-card">
            <div className="top-card">
              <p>
                {plante.type} - {plante.collection}
              </p>
            </div>
            <p className="titre">{plante.nom}</p>
            <p className="couleur">{plante.couleur_dispo}</p>
            <br />
            <br />
            <p>H : {plante.hauteur}m</p>
            <p className="floraison">
              <img src="../assets/svg/flower.svg" alt="" />
              {plante.mois_floraison} / {plante.periode_floraison}
            </p>
            <p className="exposition">
              <img src="../assets/svg/suncloud.svg" alt="" />
              {plante.exposition}
            </p>
            <p className="feuillage">
              <img src="../assets/svg/leaf.svg" alt="" />
              {plante.feuillage === "" ? "Pas d'info" : plante.feuillage}
            </p>
            <p className="eau">
              <img src="../assets/svg/waterdrop.svg" alt="" />
              {plante.besoin_eau === "" ? "Pas d'info" : plante.besoin_eau}
            </p>
            <br />
            <br />
            <p className="description">{plante.description}</p>
            <br />
            <br />
            <p>Disponibilité : {plante.dispo} </p>
            <p>
              Prix : {plante.prix === "" ? "pas d'info" : plante.prix + "€"}{" "}
            </p>
            <p>Emplacement : {plante.emplacement} </p>
            <p>Quantité produite : {plante.quantiteProd} </p>
          </div>
        </div>

        <div className="bottom-card">
          <p>rougy horticulture - Les serres d'irigny</p>
        </div>
        {/* <button
                className="modif-button"
                onClick={() => {
                  SetIsEditing(true);
                }}
              >
                Modifier
              </button> */}
      </div>
    </div>
  );
};

export default InformationPlante;
