import axios from "axios";
import React, { useState } from "react";
import ReponseDisplay from "./ReponseDisplay";
import ReponseModif from "./ReponseModif";
import config from "../config";

const DisplayQuestion = ({ question, modif, donnequiz, donneePlantes }) => {
  
  const increment = (prevCount) => {
    return prevCount + 1;
  };
  const [modifier, setModifier] = useState(false);

  const handleSuppression = (id) => {
    if (window.confirm(`Voulez vous vraiment supprimer la réponse ${id}`)) {
      axios
        .delete(
          `${config.API_URL}api/Gestionquiz/supprimerReponse/${id}`,
          config.RequestConfig()
        )
        .then((res) => {
          modif(increment);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleSuppressionQuestion = (id) => {
    if (window.confirm(`Voulez vous vraiment supprimer la question ${id}`)) {
      axios
        .delete(
          `${config.API_URL}api/Gestionquiz/supprimerQuestion/${id}`,
          config.RequestConfig()
        )
        .then((res) => {
          modif(increment);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="questionContainer">
      <p className="nbrQuestion">
        Question id : <span className="id"> {question.id}</span>
      </p>
      {question.start === "true" && (
        <p className="isStart">
          <mark>Première question</mark>
        </p>
      )}
      {modifier && (
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            setModifier(false);
            const formData = {};
            const formFields = document.querySelectorAll(
              "input, select, textarea"
            );
            formFields.forEach((field) => {
              const { name, value } = field;
              const [fieldName, fieldId] = name.split("-");
              if (name === "id_question") {
                formData["id_question"] = value;
              }
              if (name === "textQuestion") {
                formData[name] = value;
              }
              for (let index = 1; index < 7; index++) {
                if (fieldName === `Reponse${index}`) {
                  if (!formData[fieldName]) {
                    formData[fieldName] = {};
                  }
                  formData[fieldName][fieldId] = value;
                }
              }
            });
            axios
              .post(
                `${config.API_URL}api/Gestionquiz/modifQuestion`,
                formData,
                config.RequestConfig()
              )
              .then((res) => {
                modif(increment);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          <div className="allReponse">
            <input
              type="hidden"
              name="id_question"
              defaultValue={question.id}
            />
            <div className="groupInputBtn">
              <input
                className="inputTextQuestion textQuestion"
                type="text"
                name="textQuestion"
                id=""
                defaultValue={question.texte_question}
              />
              <button
                className="buttonSupprimerQuestion"
                onClick={() => handleSuppressionQuestion(question.id)}
              >
                Supprimer la question
              </button>
            </div>
            <div className="reponseTitleModif">
              <p className="tab">Action</p>
              <p className="tab">Reponse texte</p>
              <p className="tab">ID question suivante</p>
              <p className="tab">Type de tri</p>
              <p className="tab">Colonne filtrée</p>
              <p className="tab">Filtre effectué</p>
            </div>
            <div className="modif">
              <ReponseModif
                reponse={question.Reponse1}
                nombre={1}
                handleSuppresion={handleSuppression}
                donnequiz = {donnequiz}
                donneplante = {donneePlantes}
              />
              <ReponseModif
                reponse={question.Reponse2}
                nombre={2}
                handleSuppresion={handleSuppression}
                donnequiz = {donnequiz}
                donneplante = {donneePlantes}
              />
              <ReponseModif
                reponse={question.Reponse3}
                nombre={3}
                handleSuppresion={handleSuppression}
                donnequiz = {donnequiz}
                donneplante = {donneePlantes}
              />
              <ReponseModif
                reponse={question.Reponse4}
                nombre={4}
                handleSuppresion={handleSuppression}
                donnequiz = {donnequiz}
                donneplante = {donneePlantes}
              />
              <ReponseModif
                reponse={question.Reponse5}
                nombre={5}
                handleSuppresion={handleSuppression}
                donnequiz = {donnequiz}
                donneplante = {donneePlantes}
              />
              <ReponseModif
                reponse={question.Reponse6}
                nombre={6}
                handleSuppresion={handleSuppression}
                donnequiz = {donnequiz}
                donneplante = {donneePlantes}
              />
              <button className="buttonSubmitFormQuestion" type="submit">
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      )}
      {!modifier && (
        <div className="allReponse">
          <p className="textQuestion">{question.texte_question}</p>
          <div className="reponseTitle">
            <p className="tab">Reponse texte</p>
            <p className="tab">ID question suivante</p>
            <p className="tab">Type de tri</p>
            <p className="tab">Colonne filtrée</p>
            <p className="tab">Filtre effectué</p>
          </div>
          <div className="Nomodif">
            <ReponseDisplay reponse={question.Reponse1} />
            <ReponseDisplay reponse={question.Reponse2} />
            <ReponseDisplay reponse={question.Reponse3} />
            <ReponseDisplay reponse={question.Reponse4} />
            <ReponseDisplay reponse={question.Reponse5} />
            <ReponseDisplay reponse={question.Reponse6} />
          </div>
          <div className="modifier">
            <button
              className="buttonModifierQuestion"
              onClick={() => setModifier(!modifier)}
            >
              MODIFIER
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayQuestion;
