import React from 'react';

const UserListElement = ({user, userConnect, handleSupp}) => {
    return (
        <div className='userListElement'>
            <span>{user.username}</span>
            <span>{new Date(parseInt(user.lastConn)).toLocaleString()}</span>
            <span>{user.role}</span>
            {userConnect.role === "chef" ? <button onClick={()=>handleSupp(user.id)}>Supprimer</button> : null}
        </div>
    );
};

export default UserListElement;