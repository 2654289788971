import axios from "axios";
import React, { useState } from "react";
import config from "../config";

const Register = ({ handlerresponse }) => {
  const [RegisterUsername, setRegisterUsername] = useState("");
  const [RegisterPassword, setRegisterPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [Different, setDifferent] = useState(false);

  const checkThePassword = () => {
    if (checkPassword === RegisterPassword && RegisterPassword.length > 2) {
      setDifferent(false);
      register();
    } else {
      setDifferent(true);
    }
  };
  const register = () => {
    axios
      .post(
        `${config.API_URL}register`,
        {
          username: RegisterUsername,
          password: RegisterPassword,
        },
        config.RequestConfig()
      )
      .then((res) => handlerresponse(res))
      .catch((err) => console.log(err));
  };

  return (
    <div className="register_div">
      <h2>Ajout d'un utilisateur</h2>
      <form className="register_form">
        <div className="field">
          <input
            id="username"
            name="username"
            type="text"
            autoComplete="username"
            required
            placeholder="&nbsp;"
            onChange={(e) => setRegisterUsername(e.target.value)}
          />
          <label htmlFor="username">Username</label>
        </div>
        <div className="field">
          <input
            id="current-password"
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder="&nbsp;"
            required
            onChange={(e) => setRegisterPassword(e.target.value)}
          />
          <label htmlFor="current-password">Mot de passe</label>
        </div>
        <div className="field">
          <input
            id="check-password"
            name="check-password"
            type="password"
            autoComplete="new-password"
            required
            placeholder="&nbsp;"
            onChange={(e) => setCheckPassword(e.target.value)}
          />
          <label htmlFor="check-password">Confirmer le mdp</label>
          {Different && (
            <div className="differentPassword">
              <h1>Mot de passe différent ou pas assez long (2min)</h1>
            </div>
          )}
        </div>
      </form>
      <button onClick={checkThePassword}>Ajouter</button>
    </div>
  );
};

export default Register;
