import axios from "axios";
import React, { useEffect, useState } from "react";
import DisplayNavigation from "../components/DisplayNavigation";
import Header from "../components/Header";
import Inputtemplate from "../components/Inputtemplate";
import config from "../config";

const Ajoutplante = () => {
  const [offset, setOffset] = useState(0);
  const [aide, setAide] = useState(
    "Ceci est une zone d'aide je mettrai des infos importante pour pas se tromper"
  );
  const [nameimage, setnameimage] = useState("");
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    let calcul = window.innerHeight / 6;
    document.getElementById("id").style.top = offset + calcul + "px";
  }, [offset]);

  useEffect(() => {
    const syntaxe = [
      "Le nom de la plante est libre vous pouvez utilisé des lettres à accents et espace",
      "Selectionez ici le type de la plante",
      "Entrez la description de la plante vous pouvez utiliser des accents et espaces, il n'y a pas de limite du nombre de caractères",
      "Vous pouvez entrer la catégorie de la plante, laissez vide si vous ne souhaitez pas attitré de collection à la plante",
    ];
    const onFocus = (e) => {
      setAide(syntaxe[e.target.id]);
    };
    for (let index = 0; index < 4; index++) {
      const element = index;
      document.getElementById(`${element}`).addEventListener("focus", onFocus);
    }
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, []);

  return (
    <div className="ajoutplante">
      <DisplayNavigation page_name={"Ajout plante"}/>
      <div className="container">
        <Header nom_page="Ajout d'une plante" />

        <h2>
          Vous pouvez ici ajouter une plante en remplissant le formulaire
          ci-dessous :
        </h2>
        <div className="affichage_page">
          <div className="formulaire">
            <form
              action=""
              encType="multipart/form-data"
              id="form"
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                axios
                  .post(`${config.API_URL}api/plante/insertplante`, formData, config.RequestConfig())
                  .then((res) => {
                    alert(res.data)
                    document.getElementById("form").reset();
                  })
                  .catch((error) => {
                    alert(error.response.data);
                  });
              }}
            >
              <div className="infogene">
                <div className="title">
                  <h3>générale</h3>
                </div>
                <div className="input">
                  <Inputtemplate
                    type="text"
                    nom="nom"
                    id="0"
                    label="Entrer le nom de la plante"
                    require={true}
                  />

                  <select
                    name="type"
                    id="1"
                    required
                    defaultValue={""}
                  >
                    <option value="" disabled={true}>
                      Type de la plante...
                    </option>
                    <option value="Vivaces">Vivaces</option>
                    <option value="Arbustes">Arbustes</option>
                    <option value="annuelle">annuelle</option>
                    <option value="Grimpante">Grimpante</option>
                  </select>
                  <Inputtemplate
                    type="text"
                    nom="description"
                    id="2"
                    label="Entrer la description de la plante"
                    require={true}
                  />
                  <Inputtemplate
                    type="text"
                    nom="catchPhrase"
                    id="4"
                    label="Entrer une catchphrase"
                    require={false}
                  />
                  <Inputtemplate
                    type="text"
                    nom="collection"
                    id="3"
                    label="Entrer la collection a laquelle appartient la plante"
                    require={false}
                  />
                </div>
              </div>
             
              <div className="esth">
                <div className="title_esth">
                  <h3>esthétique</h3>
                </div>

                <div className="input">
                  <Inputtemplate
                    type="text"
                    nom="mois_floraison"
                    id="4"
                    label="Entrer le mois de floraison de la plante"
                    require={false}
                  />
                  
                  <select
                    name="periode_floraison"
                    id="5"
                    defaultValue={""}
                  >
                    <option value="" disabled>
                      Selectionner la periode de floraison
                    </option>
                    <option value="été">été</option>
                    <option value="automne">automne</option>
                    <option value="hiver">hiver</option>
                    <option value="printemps">printemps</option>
                  </select>
                  <div className="form_group field">
                    <input
                      className="form_field"
                      type="number"
                      step="0.1"
                      min="0"
                      name="hauteur"
                      id="6"
                      defaultValue="0"
                      placeholder="hauteur de la plante.."
                      required={false}
                    />
                    <label htmlFor="nom" className="form_label">
                      Entrer la hauteur de la plante en mètre
                    </label>
                  </div>
                  <Inputtemplate
                    type="text"
                    nom="couleur_dispo"
                    id="7"
                    label="Entrer les couleurs disponible"
                    require={false}
                  />

                  <select name="feuillage" id="8" defaultValue={""}>
                    <option value="" disabled>
                      Selectionner le feuillage
                    </option>
                    <option value="">N/D</option>
                    <option value="caduque">caduque</option>
                    <option value="persistant">persistant</option>
                  </select>
                </div>
              </div>
           
              <div className="infotechnique">
                <div className="title_infotech">
                  <h3>technique</h3>
                </div>
                <div className="input">
                  <select name="besoin_eau" id="9" defaultValue={""}>
                    <option value="" disabled>
                      Selectionner le besoin en eau
                    </option>
                    <option value="">N/D</option>
                    <option value="un_peu">un_peu</option>
                    <option value="beaucoup">beaucoup</option>
                    <option value="moyen">moyen</option>
                  </select>
                  <input
                    type="file"
                    name="photo"
                    id="10"
                    onChange={(e) => {
                      setnameimage(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="10"
                    className="btn-2"
                  >
                    selectionner{" "}
                    {nameimage ? `une autre image / ${nameimage}` : "une image"}
                  </label>
                  <select name="exposition" id="11" defaultValue={""}>
                    <option value="" disabled>
                      Selectionner l'exposition
                    </option>
                    <option value="Mi-Ombre">Mi-Ombre</option>
                    <option value="Soleil">Soleil</option>
                    <option value="Ombre">Ombre</option>
                    <option value="Polyvalent">Polyvalent</option>
                  </select>
                </div>
              </div>
             
              <div className="infoproduction">
                <div className="title_infoprod">
                  <h3>production</h3>
                </div>
                <div className="input">
                  <Inputtemplate
                    type="text"
                    nom="emplacement"
                    id="12"
                    label="Entrer l'emplacement de la plante"
                    require={false}
                  />
                  <div className="form_group field">
                    <input
                      className="form_field"
                      type="number"
                      name="prix"
                      step="0.01"
                      min="0"
                      id=""
                      defaultValue="0"
                      placeholder="prix unitaire.."
                    />
                    <label htmlFor="nom" className="form_label">
                      Entrer le prix unitaire
                    </label>
                  </div>
                  <div className="form_group field">
                    <input
                      className="form_field"
                      type="number"
                      name="quantiteProd"
                      step="100"
                      min="0"
                      id=""
                      defaultValue="0"
                      placeholder="quantite produite.."
                    />
                    <label htmlFor="nom" className="form_label">
                      Entrer la quantité produite chaque saison
                    </label>
                  </div>

                  <p className="btn-switch">
                    <input
                      type="radio"
                      id="yes"
                      value="InStock"
                      name="dispo"
                      className="btn-switch__radio btn-switch__radio_yes"
                    />
                    <input
                      type="radio"
                      defaultChecked
                      id="no"
                      name="dispo"
                      value="OutStock"
                      className="btn-switch__radio btn-switch__radio_no"
                    />
                    <label
                      htmlFor="yes"
                      className="btn-switch__label btn-switch__label_yes"
                    >
                      <span className="btn-switch__txt">En stock</span>
                    </label>
                    <label
                      htmlFor="no"
                      className="btn-switch__label btn-switch__label_no"
                    >
                      <span className="btn-switch__txt">Pas en stock</span>
                    </label>
                  </p>
                </div>
              </div>
              <button type="submit" className="button2">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                ENREGISTRER LA PLANTE
              </button>
            </form>
          </div>
          <div className="aide_container">
            <div className="aide" id="id">
              <h2>Information syntaxe</h2>
              <p>{aide}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ajoutplante;
