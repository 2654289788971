import React from "react";
import { Link } from "react-router-dom";

const Product = ({plantes, toggleDispo}) => {
  const setNewDispo = () => {
    if(plantes.dispo === "OutStock"){
      let stock = "InStock"
      return stock
    }
    if(plantes.dispo === "InStock"){
      let stock = "OutStock"
      return stock
    }
  }
  return (
    <Link to={`/products/${plantes.id}`}>
    <div className="product">
      <div className="info">
      <span className={plantes.type}>{plantes.type}</span>
      <span className="collection" style={plantes.collection === 'null' ? {display : 'none'} : {}}>{plantes.collection}</span>
      </div>
      <h2>
        {plantes.nom} 
      </h2>
      <div className="dispo">
        <span className={plantes.dispo}>{plantes.dispo}</span>
        <span className={plantes.photo === 'null' ? plantes.photo : "photook"}>{plantes.photo === 'null' ? "pas de photo" : plantes.photo}</span>
        <button className="btntoogledispo" onClick={(e)=>{toggleDispo(e, plantes.id, setNewDispo())}}>Changer Dispo</button> 
      </div>
    </div>
    </Link>
  );
};

export default Product;
