import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../AuthProvider";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate()
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");



  const { login } = useContext(AuthContext)
  const { isLoggedIn } = useContext(AuthContext);
  const { responseStatut } = useContext(AuthContext);
  const { errorMessage } = useContext(AuthContext)

  useEffect(()=>{
    if(isLoggedIn){
      navigate("/home")
    }
  }, [isLoggedIn, navigate])

  const handleLogin = () => {
    if (loginPassword && loginUsername) {
      login(loginUsername, loginPassword);
    }
  };
  return (
    <div className="all">
        <div className="login">
          <img src="../assets/png/rougy_logo.png" alt="" />
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <section className="formgroup">
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                placeholder="&nbsp;"
                required
                onChange={(e) => setLoginUsername(e.target.value)}
              />
              <label htmlFor="username">Username</label>
            </section>
            <section className="formgroup">
              <input
                id="current-password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="&nbsp;"
                required
                onChange={(e) => setLoginPassword(e.target.value)}
              />
              <label htmlFor="password">Password</label>
            </section>

            <button
              onClick={handleLogin}
              style={{
                color: loginPassword && loginUsername ? "green" : "red",
              }}
            >
              Sign in
            </button>
          </form>
          {!responseStatut && (
            <div className="errorDiv">
              <p>Un problème est survenu :</p>
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
      
    </div>
  );
};

export default Login;
