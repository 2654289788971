import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DisplayNavigation from "../components/DisplayNavigation";
import Header from "../components/Header";
import InformationPlante from "../components/InformationPlante";
import ModifPlante from "../components/ModifPlante";
import config from "../config";

const InfoProduit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [plante, SetPlante] = useState([]);
  const [isEditing, SetIsEditing] = useState(false);

  const recupedonnees = useCallback(() => {
    axios
      .get(`${config.API_URL}api/plante/byid/${id}`, config.RequestConfig())
      .then((res) => SetPlante(res.data))
      .catch((err) => console.log(err));
  }, [id]);
  useEffect(() => recupedonnees(), [isEditing, recupedonnees]);

  const SuppHandler = (e) => {
    e.preventDefault();
    if (window.confirm("voulez vous vraiment supprimer ?")) {
      axios
        .delete(
          `${config.API_URL}api/plante/supprimerplante/${id}`,
          config.RequestConfig()
        )
        .then(() => navigate("/products"))
        .catch((err) => {
          alert("ERREUR");
          console.log(err);
        });
    }
  };
  return (
    <div className="infoProduit">
      <DisplayNavigation page_name={"Info plante"} />
      <div className="container">
        <Header nom_page="Information sur la plante" />
        <button className="returnButton" onClick={() => navigate("/products")}>
          Retour
        </button>

        <div className="buttondiv">
          {!isEditing && (
            <div className="center-btn">
              <InformationPlante
                key={Date.now()}
                plante={plante}
                SetIsEditing={SetIsEditing}
              />
              <button
                className="modif-button"
                onClick={() => {
                  SetIsEditing(true);
                }}
              >
                Modifier
              </button>
            </div>
          )}

          {isEditing && (
            <form
              action=""
              encType="multipart/form-data"
              id="form"
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                SetIsEditing(false);
                axios
                  .post(
                    `${config.API_URL}api/plante/modifplante`,
                    formData,
                    config.RequestConfig()
                  )
                  .then((res) => alert(res.data))
                  .catch((error) => alert(error.response.data));
              }}
            >
              <ModifPlante plante={plante} id={id} />
              <button className="suppbutton" onClick={(e) => SuppHandler(e)}>
                SUPPRIMER
              </button>
              <button className="save-button" type="submit">
                Sauvegarder
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoProduit;
