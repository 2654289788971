import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import DisplayQuestion from "../components/DisplayQuestion";
import { useNavigate } from "react-router-dom";
import config from "../config";
import DisplayNavigation from "../components/DisplayNavigation";

const GestionQuiz = () => {
  const [modification, setModification] = useState(0);
  const [dataQuiz, setDataQuiz] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [donneePlantes, setDonneePlantes] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${config.API_URL}api/Gestionquiz/getAttributesPlante`,
        config.RequestConfig()
      )
      .then((res) => {
        setDonneePlantes(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get(`${config.API_URL}api/Gestionquiz/getQR`, config.RequestConfig())
      .then((res) => {
        setDataQuiz(res.data);
      })
      .catch((err) => console.log(err));
  }, [modification]);
  const increment = (prevCount) => {
    return prevCount + 1;
  };

  const handleChangementStart = (id) => {
    axios
      .get(
        `${config.API_URL}api/Gestionquiz/changeStart/${id}`,
        config.RequestConfig()
      )
      .then((res) => setModification(increment))
      .catch((err) => console.log(err));
  };
  const handleAddQuestion = () => {
    axios
      .get(`${config.API_URL}api/Gestionquiz/addQuestion`, config.RequestConfig())
      .then(() => setModification(increment))
      .catch((err) => console.log(err));
  };
  return (
    <div className="gestionQuiz">
      <DisplayNavigation page_name={"Gestion quiz"}/>
      <div className="container">
        <Header nom_page="gestion quiz" />
        <div className="listquiz">
          <button
            onClick={() =>
              navigate("/visualisation", { state: { questions: dataQuiz } })
            }
          >
            Voir le quiz
          </button>
          <form
            action=""
            className="formBegin"
            onSubmit={(e) => {
              e.preventDefault();
              handleChangementStart(inputValue);
            }}
          >
            <label htmlFor="start">
              Selectionner la question par laquelle commencer :{" "}
            </label>
            <select
              name="start"
              id="start"
              className="selectBegin"
              onChange={(e) => setInputValue(e.target.value)}
              defaultValue={""}
            >
              <option value="" disabled>
                Selectionner l'id de la question
              </option>
              {dataQuiz.map((question, index) => (
                <option value={question.id} key={index}>
                  {question.id}
                </option>
              ))}
            </select>
            <button type="submit" className="buttonBegin">
              Valider
            </button>
          </form>
        </div>
        {dataQuiz.map(
          (question, index) =>
            question.start === "true" && (
              <div className="listquiz" key={index}>
                <DisplayQuestion
                  question={question}
                  modif={setModification}
                  donnequiz={dataQuiz}
                  donneePlantes={donneePlantes}
                />
              </div>
            )
        )}
        {dataQuiz.map(
          (question, index) =>
            question.start !== "true" && (
              <div className="listquiz" key={index}>
                <DisplayQuestion
                  question={question}
                  modif={setModification}
                  donnequiz={dataQuiz}
                  donneePlantes={donneePlantes}
                />
              </div>
            )
        )}
        <div className="listquiz">
          <button
            className="buttonAjouterQuestion"
            onClick={() => handleAddQuestion()}
          >
            Ajouter une question
          </button>
        </div>
      </div>
    </div>
  );
};

export default GestionQuiz;
