import React, { createContext, useState } from "react";
import axios from "axios";
import config from "./config";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [responseStatut, setResponseStatut] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const verifyLogin = () => {
    axios
      .get(`${config.API_URL}islogged`, config.RequestConfig())
      .then((res) => {
        setIsLoggedIn(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoggedIn(false);
      });
  };
  const login = (loginUsername, loginPassword) => {
    axios
      .post(`${config.API_URL}login`, {
        username: loginUsername,
        password: loginPassword,
      })
      .then((res) => {
        if (res.data.success === true) {
          //setResponseStatut(true);
          //setIsLoggedIn(res.data.success);
          localStorage.setItem("token", res.data.token);
          verifyLogin();
        }
        if (res.data.success === false) {
          setResponseStatut(res.data.success);
          setErrorMessage(res.data.message);
          setIsLoggedIn(false);
        }
      })
      .catch((err) => {
        setResponseStatut(false);
        setErrorMessage(err.message);
      });
  };
  return (
    <AuthContext.Provider
      value={{ verifyLogin, isLoggedIn, login, responseStatut, errorMessage }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
