import React from "react";

const CardQuestionVisu = ({ question }) => {
  const Reponses = [
    "Reponse1",
    "Reponse2",
    "Reponse3",
    "Reponse4",
    "Reponse5",
    "Reponse6",
  ];
  return (
    <div className="cardvisuquiz">
      <span className="question">{question.texte_question}</span>
      <div className="reponses">
        {Reponses.map(
          (element, index) =>
            question[element] !== null && (
              <p className="reponse" key={index}>
                {question[element].text_reponse}
              </p>
            )
        )}
      </div>
    </div>
  );
};

export default CardQuestionVisu;
