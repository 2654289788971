import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = ({ size, closenav }) => {
  return (
    <div className={"navigation-" + size}>
      <button className="btn-closenav" onClick={() => closenav(false)}>
        X
      </button>
      <ul>
        <img src="../assets/png/rougy_logo.png" alt="" />
        <NavLink
          to="/home"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          onClick={() => closenav(false)}
        >
          <li>Accueil</li>
        </NavLink>
        <NavLink
          to="/products"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          onClick={() => closenav(false)}
        >
          <li>Gestion des plantes</li>
        </NavLink>
        <NavLink
          to="/ajoutplante"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          onClick={() => closenav(false)}
        >
          <li>Ajouter une plante</li>
        </NavLink>
        <NavLink
          to="/gestionUser"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          onClick={() => closenav(false)}
        >
          <li>Ajouter un utilisateur</li>
        </NavLink>
        <NavLink
          to="/gestionexcel"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          onClick={() => closenav(false)}
        >
          <li>Outil Excel</li>
        </NavLink>
        <NavLink
          to="/gestionquiz"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          onClick={() => closenav(false)}
        >
          <li>Gestion quiz</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;
