import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheminDisplay from "../components/CheminDisplay";

const VisualisationQuiz = () => {
  let questions = useLocation().state.questions;
  let navigate = useNavigate();
  let chemin1 = [];
  let chemin2 = [];
  let chemin3 = [];
  let chemin4 = [];
  let chemin5 = [];
  let chemin6 = [];
  const getFirstQuestion = () => {
    return questions.find((question) => {
      return question.start === "true";
    });
  };

  const getIndexQuestionSuivante = (reponse) => {
    return questions.find((question) => {
      return question.id === reponse.id_question_suivante;
    });
  };
  const definePath = (numeroReponse, array) => {
    let questionSuivante;
    let QuestionPrecedente = getFirstQuestion();
    array.push(QuestionPrecedente.id);
    if (QuestionPrecedente[numeroReponse] === null) {
      return array;
    }
    questionSuivante = getIndexQuestionSuivante(
      QuestionPrecedente[numeroReponse]
    );
    if (questionSuivante !== undefined) {
      array.push(questionSuivante.id);
      QuestionPrecedente = questionSuivante;
    } else {
      return array;
    }
    while (QuestionPrecedente["Reponse1"].id_question_suivante !== 0) {
      questionSuivante = getIndexQuestionSuivante(
        QuestionPrecedente["Reponse1"]
      );
      array.push(questionSuivante.id);
      QuestionPrecedente = questionSuivante;
    }
    return array;
  };
  definePath("Reponse1", chemin1);
  definePath("Reponse2", chemin2);
  definePath("Reponse3", chemin3);
  definePath("Reponse4", chemin4);
  definePath("Reponse5", chemin5);
  definePath("Reponse6", chemin6);

  return (
    <div className="visualisationquiz">
        <button onClick={()=>navigate('/gestionquiz')}>Retour</button>
      {chemin1.length > 1 && (
        <CheminDisplay questions={questions} chemin={chemin1} nom="chemin 1" />
      )}
      {chemin2.length > 1 && (
        <CheminDisplay questions={questions} chemin={chemin2} nom="chemin 2" />
      )}
      {chemin3.length > 1 && (
        <CheminDisplay questions={questions} chemin={chemin3} nom="chemin 3" />
      )}
      {chemin4.length > 1 && (
        <CheminDisplay questions={questions} chemin={chemin4} nom="chemin 4" />
      )}
      {chemin5.length > 1 && (
        <CheminDisplay questions={questions} chemin={chemin5} nom="chemin 5" />
      )}

      {chemin6.length > 1 && (
        <CheminDisplay questions={questions} chemin={chemin6} nom="chemin 6" />
      )}
    </div>
  );
};

export default VisualisationQuiz;
