import React from "react";
import axios from "axios";
import config from "../config";

const ButtonExportXlsx = () => {
  const token = localStorage.getItem("token");
  return (
    <button
      className="buttonexportxlsx"
      onClick={() => {
        axios({
          url: `${config.API_URL}api/excel/exportxlsx`,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ListePlantes.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }}
    >
      Exporter en XLSX
    </button>
  );
};

export default ButtonExportXlsx;
