import {React, useContext, useEffect } from 'react';
import { AuthContext } from "../AuthProvider";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthLayout = () => {
    const { verifyLogin, isLoggedIn } = useContext(AuthContext);
    const location = useLocation();
    
    useEffect(()=> {
        verifyLogin()
    }, [verifyLogin, location])
   
    if(isLoggedIn){
        return <Outlet />
    }
    return <Navigate to={"/login"} replace />
};

export default AuthLayout;