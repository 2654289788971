import React from "react";

const Input_template = ({type, nom, id, label,require}) => {
  return (
    <div className="form_group field">
      <input
        className="form_field"
        type={`${type}`} 
        name={`${nom}`} 
        id={`${id}`} 
        placeholder="&nbsp;"
        required={require}
      />
      <label htmlFor={`${nom}`} className="form_label">
      {`${label}`}
      </label > 
    </div>
  );
};

export default Input_template;
