import { React } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Ajoutplante from "./pages/Ajoutplante";
import Home from "./pages/Home";
import Products from "./pages/Products";
import InfoProduit from "./pages/InfoProduit";
import Login from "./pages/Login";
import GestionUser from "./pages/GestionUser";
import AuthProvider from "./AuthProvider";
import AuthLayout from "./components/AuthLayout";
import GestionExcel from "./pages/GestionExcel";
import GestionQuiz from "./pages/GestionQuiz";
import VisualisationQuiz from "./pages/VisualisationQuiz";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<AuthLayout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/ajoutplante" element={<Ajoutplante />} />
            <Route path="/products/:id" element={<InfoProduit />} />
            <Route path="/gestionUser" element={<GestionUser />} />
            <Route path="/gestionexcel" element={<GestionExcel />} />
            <Route path="/gestionquiz" element={<GestionQuiz />}/>
            <Route path="/visualisation" element={<VisualisationQuiz/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
