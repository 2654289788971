const API_URL = "https://api.gestionbdd.romain-mourieras.fr/";

const RequestConfig = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const config = {
    API_URL,
    RequestConfig
}
export default config;