import axios from "axios";
import React, { useState } from "react";
import ButtonExportXlsx from "../components/ButtonExportXlsx";
import DisplayNavigation from "../components/DisplayNavigation";
import Header from "../components/Header";
import config from "../config";

const GestionExcel = () => {
  const [nomFichier, setNomFichier] = useState("")
  const token = localStorage.getItem("token");
  return (
    <div className="gestionexcel">
      <DisplayNavigation page_name={"Gestion excel"}/>
      <div className="container">
        <Header nom_page="gestion base de donnée avec excel" />
        <div className="divXls">
          <div className="exportXls">
            <h2>Exportation dans le format excel</h2>
            <p>Téléchargement d'un fichier bddPlantes.xlsx</p>
            <ButtonExportXlsx />
          </div>
          <div className="importXls">
            <h2>Importation d'un classeur excel</h2>
            <p>Format accepté : XLSX</p>
            <p>Classeur avec 3 pages : vivaces, annuelles et arbustes</p>
            <p>Tri automatique des doublons avec le nom de la plante</p>
            <form 
              action=""
              encType="multipart/form-data"
              id="form"
              onSubmit={(e) => {
                document.querySelector('.submit').disabled = true;
                document.querySelector('.submit').value = 'En cours...'
                e.preventDefault();
                const formData = new FormData(e.target);
                axios
                  .post(`${config.API_URL}api/excel/importxlsx`, formData, {
                    responseType: "blob",
                    headers: {
                      Authorization: `Bearer ${token}`,
                    }
                  })
                  .then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "logs.txt");
                    document.body.appendChild(link);
                    link.click();
                    document.querySelector('.submit').disabled = false;
                    document.querySelector('.submit').value = 'Envoyer'
                  })
                  .catch((error) => {
                    alert(error.response.data);
                  });
              }}>
              <input
                className="inputXls"
                type="file"
                name="importXls"
                id="importXls"
                onChange={(e) => {
                  setNomFichier(e.target.value)
                  }}
              />
              <label htmlFor="importXls" className="btn-2">
                {nomFichier ? `${nomFichier}` : `Choisir un fichier`}
              </label>
              <input type="submit" value="Envoyer" className="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionExcel;
