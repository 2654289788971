import axios from "axios";
import React, { useEffect, useState } from "react";
import DisplayNavigation from "../components/DisplayNavigation";
import Header from "../components/Header";
import Product from "../components/Product";
import config from "../config";

const Products = () => {
  const [data, setData] = useState([]);
  const [selectType, SetSelectType] = useState("");
  const [searchname, SetSearchName] = useState("");
  const [selectDispo, SetSelectDispo] = useState("");
  const [selectnoPhoto, SetSelectNoPhoto] = useState("");
  const [play, SetPlay] = useState(false);
  const type = ["Vivaces", "annuelle", "Arbustes"];
  useEffect(() => {
    axios
      .get(`${config.API_URL}api/plante/allPlante`, config.RequestConfig())
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, [play]);

  const toggleDispo = (e, id, dispo) => {
    e.preventDefault();
    axios
      .post(
        `${config.API_URL}api/plante/toggledispo`,
        { id, dispo },
        config.RequestConfig()
      )
      .then(() => SetPlay(!play))
      .catch((err) => console.log(err));
  };
  return (
    <div className="products">
      <DisplayNavigation page_name={"Toutes les plantes"}/>
      <div className="container">
        <Header nom_page="Toutes les plantes" />
        <div className="filter-container">
          <div className="search">
            <img srcSet="../assets/svg/recherche.svg" alt="" />
            <input
              className="searchfield"
              type="text"
              placeholder="rechercher une plante par son nom"
              name="nameSearch"
              value={searchname}
              onChange={(e) => SetSearchName(e.target.value)}
            />
          </div>
          <div className="select">
            <div className="radio">
              <div className="switch_box box_1">
                <input
                  type="checkbox"
                  name="photo"
                  className="switch_1"
                  value={selectnoPhoto === "" ? "null" : ""}
                  id=""
                  onChange={(e) => SetSelectNoPhoto(e.target.value)}
                />
                <label htmlFor="photo">Pas de photo</label>
              </div>
            </div>

            <select
              onChange={(e) => SetSelectType(e.target.value)}
              value={selectType}
            >
              <option value="">Type</option>
              {type.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <select
              name=""
              id=""
              value={selectDispo}
              onChange={(e) => SetSelectDispo(e.target.value)}
            >
              <option value="">Status</option>
              <option value="InStock">En stock</option>
              <option value="OutStock">Pas en stock</option>
            </select>
            <button
              className="btnreset"
              onClick={() => {
                if (searchname !== "") {
                  SetSearchName("");
                }
                if (selectType !== "") {
                  SetSelectType("");
                }
                if (selectDispo !== "") {
                  SetSelectDispo("");
                }
                if (selectnoPhoto !== "") {
                  SetSelectNoPhoto("");
                }
              }}
            >
              Reinitialiser les filtres
            </button>
          </div>

          {/* <ButtonExportXlsx /> */}
        </div>

        <div className="allproducts">
          {data
            .filter((plante) =>
              plante.nom.toLowerCase().includes(searchname.toLowerCase())
            )
            .filter((plante) => plante.type.includes(selectType))
            .filter((plantes) => plantes.dispo.includes(selectDispo))
            .filter((plantes) => plantes.photo.includes(selectnoPhoto))
            .map((plantes, index) => (
              <Product
                key={index}
                plantes={plantes}
                toggleDispo={toggleDispo}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
