import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplayNavigation from "../components/DisplayNavigation";
import Header from "../components/Header";
import Register from "../components/Register";
import UserListElement from "../components/UserListElement";
import config from "../config";

const GestionUser = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState([]);
  const [ConnectUser, setConnectUser] = useState({})
  const [addUser, setAddUser] = useState(false);
  const [suppression, setSuppression] = useState(false)
  const handleSupp = (id) => {
    if(window.confirm("Voulez vous vraiment supprimer ?")){
      axios.delete(`${config.API_URL}api/user/supprimeruser/${id}`, config.RequestConfig())
      .then((res)=>{
        setSuppression(!suppression)
        alert(res.data)
      })
      .catch((err)=>{
        alert(err.response.data)
      })
    }
  }
  useEffect(() => {
    axios
      .get(`${config.API_URL}api/user/allUsers`, config.RequestConfig())
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        alert(err.response.data)
      })
  }, [suppression, addUser]);

  useEffect(()=>{
    axios.get(`${config.API_URL}api/user/current`,config.RequestConfig()).then((res)=>{
      setConnectUser(res.data)
    })
    .catch((err) => {
      navigate('/login')
    })
  }, [navigate])
  const handlerresponse = (response) => {
    alert(response.data);
    if (response.data === "user created !") {
      setAddUser(!addUser);
    }
  };
  return (
    <div className="Gestion_user">
      <DisplayNavigation page_name={"Gestion utilisateurs"}/>
      <div
        className="container"
        style={{ filter: addUser ? "blur(2px)" : "none" }}
      >
        <Header nom_page="Gestion des utilisateurs" />
        <div className="listeUsers">
          <div className="toplist">
            <p className="titleList">La team de choc</p>
            <button
              onClick={() => {
                setAddUser(!addUser);
              }}
            >
              Ajouter
            </button>
          </div>
          <div className="userListElementExemple">
            <span>Username</span>
            <span>Dernière connexion</span>
            <span>role</span>
           {ConnectUser.role === "chef" ? <span>Supprimer</span> : null}
          </div>
          {users.map((user, index) => (
            <UserListElement user={user} key={index} userConnect={ConnectUser} handleSupp={handleSupp}/>
          ))}
        </div>
      </div>
      {addUser && (
        <div className="addUser">
          <div className="open">
            <button
              className="closeadduser"
              onClick={() => {
                setAddUser(!addUser);
              }}
            >
              X
            </button>
            <Register handlerresponse={handlerresponse} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GestionUser;
