import React from "react";

const ReponseDisplay = ({ reponse }) => {
  return (
    <div className="reponse">
      <p className="tab">{reponse?.text_reponse}</p>
      <p className="tab">{reponse?.id_question_suivante === 0 ? "FIN" : reponse?.id_question_suivante}</p>
      <p className="tab">{reponse?.type_tri}</p>
      <p className="tab">{reponse?.colonne_filtre}</p>
      <p className="tab">{reponse?.filtre}</p>
    </div>
  );
};

export default ReponseDisplay;
