import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../config";

const ReponseModif = ({
  reponse,
  nombre,
  handleSuppresion,
  donnequiz,
  donneplante,
}) => {
  const [possibleFiltre, setPossibleFiltre] = useState([]);
  const [colonneFiltre, setColonneFiltre] = useState("");
  const [typeTri, setTypeTri] = useState("");
  const [filtre, setFiltre] = useState("");

  useEffect(() => {
    if (reponse !== null) {
      setColonneFiltre(reponse.colonne_filtre);
      setTypeTri(reponse.type_tri);
      setFiltre(reponse.filtre);
      if (
        reponse.colonne_filtre === "type" ||
        reponse.colonne_filtre === "collection" ||
        reponse.colonne_filtre === "exposition" ||
        reponse.colonne_filtre === "periode_floraison" ||
        reponse.colonne_filtre === "feuillage"
      ) {
        axios
          .get(
            `${config.API_URL}api/Gestionquiz/getPossibleAnswer/${reponse.colonne_filtre}`,
            config.RequestConfig()
          )
          .then((res) => setPossibleFiltre(res.data))
          .catch((err) => console.log(err));
      }
    } else {
      setTypeTri("");
      setColonneFiltre("");
    }
  }, [reponse]);

  const handleChangeFiltre = (e) => {
    let colonneFiltree = e.target.value;
    if (
      colonneFiltree === "type" ||
      colonneFiltree === "collection" ||
      colonneFiltree === "exposition" ||
      colonneFiltree === "periode_floraison" ||
      colonneFiltree === "feuillage"
    ) {
      setColonneFiltre(e.target.value);
      axios
        .get(
          `${config.API_URL}api/Gestionquiz/getPossibleAnswer/${colonneFiltree}`,
          config.RequestConfig()
        )
        .then((res) => setPossibleFiltre(res.data))
        .catch((err) => console.log(err));
    } else {
      setColonneFiltre("");
    }
  };
  return (
    <div className="reponse">
      <div className="cellModifTab">
        {reponse !== null && (
          <button
            className="buttonSuppReponse"
            onClick={() => handleSuppresion(reponse.id)}
          >
            Supprimer
          </button>
        )}
      </div>
      <input
        type="hidden"
        name={"Reponse" + nombre + "-id_reponse"}
        defaultValue={reponse?.id}
        className="cellModifTab"
      />
      <input
        type="text"
        name={"Reponse" + nombre + "-textReponse"}
        id=""
        defaultValue={reponse?.text_reponse}
        className="cellModifTab"
        placeholder="text afficher"
      />
      <select
        name={"Reponse" + nombre + "-id_question_suivante"}
        id=""
        className="cellModifTab"
        defaultValue={reponse?.id_question_suivante}
      >
        <option value="">Selectioner...</option>
        <option value="0">FIN</option>
        {donnequiz.map((question, index) => (
          <option value={question.id} key={index}>
            {question.id}
          </option>
        ))}
      </select>
      <select
        name={"Reponse" + nombre + "-type_tri"}
        id=""
        className="cellModifTab"
        style={reponse ? {} : { color: "grey" }}
        defaultValue={reponse ? reponse.type_tri : ""}
        onChange={(e) => setTypeTri(e.target.value)}
      >
        <option value="" disabled>
          type de tri
        </option>
        <option value="AUCUN">AUCUN</option>
        <option value="STRICT">Egale au filtre</option>
        <option value="STRING_MULTI">L'un de ces mots cles</option>
        <option value="INT_AVG">Entre ces chiffres</option>
      </select>
      <select
        name={"Reponse" + nombre + "-colonne_filtre"}
        id=""
        className="cellModifTab"
        defaultValue={reponse?.colonne_filtre}
        onChange={(e) => handleChangeFiltre(e)}
      >
        <option value="">Selectionner..</option>
        {donneplante.map((colonne, index) => (
          <option value={colonne} key={index}>
            {colonne}
          </option>
        ))}
      </select>
      {colonneFiltre !== "" && typeTri === "STRICT" ? (
        possibleFiltre.length === 0 ? (
          <p>chargement...</p>
        ) : (
          <select
            name={"Reponse" + nombre + "-filtre"}
            id=""
            className="cellModifTab"
            defaultValue={filtre}
          >
            {possibleFiltre.map((filtre, index) => (
              <option value={filtre[colonneFiltre]} key={index}>
                {filtre[colonneFiltre]}
              </option>
            ))}
          </select>
        )
      ) : (
        <input
          type="text"
          name={"Reponse" + nombre + "-filtre"}
          id=""
          defaultValue={reponse?.filtre}
          className="cellModifTab"
          placeholder="Filtre souhaité"
        />
      )}
    </div>
  );
};

export default ReponseModif;
