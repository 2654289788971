import React from "react";
import CardQuestionVisu from "./CardQuestionVisu";

const CheminDisplay = ({ questions, chemin, nom }) => {
  return (
    <div className="chemin 1">
      <span className="cheminnumber">{nom}</span>
      {questions.map((question, index) =>
        chemin.map(
          (idquestion, index) =>
            question.id === idquestion && (
              <CardQuestionVisu question={question} key={index} />
            )
        )
      )}
      <span className="endchemin">FIN</span>
    </div>
  );
};

export default CheminDisplay;
