import React, { useEffect, useState } from "react";
import Header from "../components/Header";

import axios from "axios";
import Chart from "chart.js/auto";
import config from "../config";
import DisplayNavigation from "../components/DisplayNavigation";

const Home = () => {
  const [user, setUser] = useState({});
  const [stats, setStats] = useState("");

  const canvasRef = React.useRef();
  const getuser = () => {
    axios
      .get(`${config.API_URL}api/user/current`, config.RequestConfig())
      .then((res) => setUser(res.data))
      .catch((err) => console.log(err));
  };
  const getStats = () => {
    axios
      .get(`${config.API_URL}api/stats`, config.RequestConfig())
      .then((res) => setStats(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getStats();
    getuser();
  }, []);

  useEffect(() => {
    const chart = new Chart(canvasRef.current, {
      type: "doughnut",
      data: {
        labels: ["En stock", "Pas en stock"],
        datasets: [
          {
            data: [stats.InStock, stats.OutStock],
            backgroundColor: ["#231942", "#B4B6BB"],
            borderWidth: 0,
            weight: 100,
          },
        ],
      },
    });
    return () => {
      chart.destroy();
    };
  }, [stats]);
  return (
    <div className="home_page">
      <DisplayNavigation page_name={"Accueil"}/>
      <div className="container">
        <Header nom_page="Accueil" />
        <p className="helloTitle">
          Bonjour {user.username} voici une overview des données,{" "}
        </p>
        <div className="stats">
          <div className="left">
            <div className="statsType">
              <div className="square">
                <img src="../assets/png/vivace.png" alt="" />
                <p>{stats.vivaces} vivaces</p>
              </div>
              <div className="square">
                <img src="../assets/png/annuelle.png" alt="" />
                <p>{stats.Annuelles} annuelles</p>
              </div>
              <div className="square">
                <img src="../assets/png/arbuste.png" alt="" />
                <p>{stats.Arbustes} arbustes</p>
              </div>
            </div>

            <div className="downline">
              <div className="square-down">
                <img src="../assets/png/plante.png" alt="" />
                <p>{stats.Plantes} plantes</p>
              </div>
              <div className="square-down">
                <img src="../assets/png/utilisateur.png" alt="" />
                <p>{stats.user} utilisateurs</p>
              </div>
            </div>
          </div>

          <div className="diagrammeStock">
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
