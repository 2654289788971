import React from "react";
import config from "../config";

const ModifPlante = ({ plante, id }) => {
  return (
    <div className="all1">
      <div className="info_plante">
        <div className="card">
          <div className="middle-card">
            <div className="photo photo-modif">
              {plante.photo === undefined ? (
                <p>Loading</p>
              ) : plante.photo === "null" ? (
                <div className="imgContainer">
                  <img
                    src={`${config.API_URL}images/${plante.photo}.png`}
                    alt=""
                    className="image"
                  />
                  <img
                    src={`${config.API_URL}images/${plante.photo}.png`}
                    alt=""
                    className="bgphoto"
                  />
                </div>
              ) : (
                <div className="imgContainer">
                  <img
                    src={`${config.API_URL}images/${plante.photo}`}
                    alt=""
                    className="image"
                  />
                  <img
                    src={`${config.API_URL}images/${plante.photo}`}
                    alt=""
                    className="bgphoto"
                  />
                </div>
              )}
              <span className="nomPhotoActuelle">Actuel : {plante.photo}</span>
              <div className="inputImg">
                <label htmlFor="10">Changer : </label>
                <input type="file" name="photo" id="10" />
              </div>
              <input type="hidden" name="photo" defaultValue={plante.photo} />
            </div>
            <div className="information-card">
              <div className="top-card">
                <select
                  name="type"
                  id="1"
                  required
                  defaultValue={plante.type}
                  className="inputtop"
                >
                  <option value="Vivaces">Vivaces</option>
                  <option value="Arbustes">Arbustes</option>
                  <option value="annuelle">annuelle</option>
                  <option value="Grimpante">Grimpante</option>
                </select>{" "}
                <p>-</p>
                <input
                  className="inputtop"
                  type="text"
                  name="collection"
                  id=""
                  defaultValue={plante.collection}
                />
              </div>
              <input
                type="text"
                name="nom"
                id="1"
                defaultValue={plante.nom}
                className="titre"
              />

              <input
                type="text"
                name="couleurdispo"
                id=""
                defaultValue={plante.couleur_dispo}
                className="couleur"
              />

              <br />

              <div className="inputdiv">
                <label htmlFor="hauteur">H : &nbsp;</label>
                <input
                  type="number"
                  step="0.1"
                  min="0"
                  name="hauteur"
                  id="6"
                  defaultValue={plante.hauteur}
                />
              </div>
              <div className="inputdiv">
                <label htmlFor="mois_floraison">
                  <img src="../assets/svg/flower.svg" alt="" />
                </label>
                <input
                  type="text"
                  name="mois_floraison"
                  id=""
                  defaultValue={plante.mois_floraison}
                />
                <label htmlFor="periode_floraison">&nbsp;/&nbsp;</label>
                <select
                  name="periode_floraison"
                  id="5"
                  required
                  defaultValue={plante.periode_floraison}
                >
                  <option value="été">été</option>
                  <option value="automne">automne</option>
                  <option value="hiver">hiver</option>
                  <option value="printemps">printemps</option>
                </select>
              </div>
              <div className="inputdiv">
                <label htmlFor="exposition">
                  <img src="../assets/svg/suncloud.svg" alt="" />
                </label>
                <select
                  name="exposition"
                  id="11"
                  defaultValue={plante.exposition}
                >
                  <option value="Mi-Ombre">Mi-Ombre</option>
                  <option value="Soleil">Soleil</option>
                  <option value="Ombre">Ombre</option>
                  <option value="Polyvalent">Polyvalent</option>
                </select>
              </div>
              <div className="inputdiv">
                <label htmlFor="feuillage">
                  <img src="../assets/svg/leaf.svg" alt="" />
                </label>
                <select name="feuillage" id="8" defaultValue={plante.feuillage}>
                  <option value="">N/D</option>
                  <option value="caduque">caduque</option>
                  <option value="persistant">persistant</option>
                </select>
              </div>
              <div className="inputdiv">
                <label htmlFor="besoin_eau">
                  <img src="../assets/svg/waterdrop.svg" alt="" />
                </label>
                <select
                  name="besoin_eau"
                  id="9"
                  defaultValue={plante.besoin_eau}
                >
                  <option value="">N/D</option>
                  <option value="un_peu">un_peu</option>
                  <option value="beaucoup">beaucoup</option>
                  <option value="moyen">moyen</option>
                </select>
              </div>
              <br />

              <textarea
                spellCheck
                name="description"
                className="descriptioninput"
                defaultValue={plante.description}
              ></textarea>
              <br />
              <br />
              <div className="inputdiv">
                <label htmlFor="dispo">Disponibilité :&nbsp;</label>
                <select name="dispo" id="" defaultValue={plante.dispo}>
                  <option value="OutStock">NON</option>
                  <option value="InStock">OUI</option>
                </select>
              </div>
              <div className="inputdiv">
                <label htmlFor="prix">Prix : &nbsp;</label>
                <input
                  type="number"
                  name="prix"
                  step="0.01"
                  min="0"
                  id=""
                  defaultValue={plante.prix}
                />
                <label htmlFor="prix">€</label>
              </div>
              <div className="inputdiv">
                <label htmlFor="emplacement">Emplacement : &nbsp;</label>
                <input
                  type="text"
                  name="emplacement"
                  id=""
                  defaultValue={plante.emplacement}
                />
              </div>
              <div className="inputdiv">
                <label htmlFor="quantiteProd">Quantité produite : &nbsp;</label>
                <input
                  type="number"
                  name="quantiteProd"
                  step="100"
                  min="0"
                  id="test"
                  defaultValue={plante.quantiteProd}
                />
              </div>
            </div>
          </div>
          <div className="bottom-card">
            <p>rougy horticulture - Les serres d'irigny</p>
          </div>
          <input type="number" name="id" hidden defaultValue={id} />
        </div>
      </div>
    </div>
  );
};

export default ModifPlante;
